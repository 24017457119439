import Button from '@mui/material/Button';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { teal } from '@mui/material/colors';
import React, { useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import KcLogo from '../../images/HiveAuth_name_landscape_dark.png';
import Image from 'components/Image';
import HAS from 'helpers/has';
import Divider from '@mui/material/Divider';
import DialogContentText from '@mui/material/DialogContentText';
import QRious from 'qrious';
import UserContext from 'components/General/UserContext';
import Cookie from 'js-cookie';
import { nanoid } from 'nanoid';
import Router from 'next/router';

const btnTheme = createTheme({
  palette: {
    primary: {
      main: teal[600],
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      justifyContent: 'left',
    },
  },
});

export default function HiveAuthButton({ logo, name, isLogin, isContained }) {
  const [handleShowHiveAuthInput, setHandleShowHiveAuthInput] = useState(false);
  const [username, setUsername] = useState(undefined);
  const [qrCode, setQrCode] = useState(undefined);
  const [csrfState, setCsrfState] = useState(undefined);

  useEffect(() => {
    const existingCookie = Cookie.get('csrf_state');
    if (existingCookie) {
      setCsrfState(existingCookie);
    } else {
      const state = nanoid();
      Cookie.set('csrf_state', state);
      setCsrfState(state);
    }
  }, []);

  const { theme } = useContext(UserContext);
  const isDarkTheme = theme === 'dark';

  const handleUsernameInput = () => event => {
    setUsername(event.target.value.toLowerCase().trim());
  };

  const { enqueueSnackbar } = useSnackbar();

  const newNotification = notification => {
    if (notification !== undefined) {
      let variant = 'success';
      if (notification.success === false) {
        variant = 'error';
      }
      enqueueSnackbar(notification.message, { variant });
    }
  };

  const handleLogin = () => {
    const APP_META = {
      name: 'TravelFeed',
      description: 'Hive frontend for travel blogs',
      icon: 'https://travelfeed.com/favicon.svg',
    };
    // Create an authentication object
    const auth = {
      username, // required - replace "username" with your Hive account name (without the @)
      //   expire: undefined,
      //   key: undefined,
    };
    const signedMessageObj = { type: 'posting', app: 'travelfeed.app' };
    const timestamp = parseInt(new Date().getTime() / 1000, 10);
    const messageObj = {
      signed_message: signedMessageObj,
      authors: [username],
      timestamp,
    };
    // optional - create a challenge to sign with the posting key
    const challenge_data = {
      key_type: 'posting',
      challenge: JSON.stringify(messageObj),
    };

    HAS.authenticate(auth, APP_META, challenge_data, evt => {
      evt.host = 'wss://hive-auth.arcange.eu';
      const URI = `has://auth_req/${btoa(JSON.stringify(evt))}`;
      setQrCode(URI);
    })
      .then(res => {
        const jwt = messageObj;
        jwt.signatures = [res.data.challenge.challenge];
        const jwtSigned = btoa(JSON.stringify(jwt));
        Router.push({
          pathname: '/login/hive',
          query: { access_token: jwtSigned, state: csrfState },
        });
      }) // Authentication request approved
      .catch(err => {
        console.log(err);
        newNotification({
          success: false,
          message: 'HiveAuth request expired',
        });
        setQrCode(undefined);
      }); // Authentication request rejected or error occured
  };

  const handleSubmit = () => {
    if (username && username.length > 2) handleLogin();
    else
      newNotification({
        success: false,
        message: 'Enter your Hive username first',
      });
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') handleSubmit();
  };

  if (qrCode) {
    const QR = new QRious({
      background: 'white',
      backgroundAlpha: 0,
      foreground: isDarkTheme ? 'white' : 'black',
      size: 200,
      value: qrCode,
    });

    return (
      <>
        <DialogContentText className="text-center">
          Please tap or scan the following QR code with your HiveAuth compatible
          mobile wallet app
        </DialogContentText>
        <div className="text-center tw-pt-3">
          <a href={qrCode} target="_blank">
            <Image
              unoptimized
              src={QR.toDataURL('image/png')}
              height={300}
              width={300}
              alt="Scan to login"
            />
          </a>
        </div>
        <div className="text-center tw-pb-5">
          <Button onClick={() => setQrCode(undefined)} variant="outlined">
            back
          </Button>
        </div>
        <Divider />
      </>
    );
  }
  if (handleShowHiveAuthInput)
    return (
      <>
        <TextField
          variant="standard"
          autoFocus
          value={username}
          onChange={handleUsernameInput()}
          margin="dense"
          id="username"
          label="Hive username"
          fullWidth
          onKeyPress={handleKeyPress}
          inputProps={{
            maxLength: 16,
          }}
        />
        <Button
          size="large"
          fullWidth
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          style={{ height: '40px' }}
        >
          <Image
            src={KcLogo}
            alt="Login with Hive Keychain"
            height={32.5}
            width={187.8}
          />
        </Button>
        <hr />
      </>
    );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={btnTheme}>
        <Button
          color="primary"
          variant={isContained ? 'contained' : 'outlined'}
          fullWidth
          size="large"
          onClick={() => setHandleShowHiveAuthInput(true)}
        >
          <div className="tw-flex tw-items-stretch tw-w-full">
            {logo}
            <div className="tw-w-full tw-text-center">
              {isLogin ? 'Login' : 'Sign up'} with {name}
            </div>
          </div>
        </Button>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
